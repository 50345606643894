import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { m } from "framer-motion";
import { varZoom } from "../components/animate/variants";
// material
import { Grid } from '@material-ui/core';
//
import Product from '../components/Product';

// ----------------------------------------------------------------------

ProductGrid.propTypes = {
  products: PropTypes.array.isRequired
};

export default function ProductGrid({ products, ...other }) {
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={6}
      {...other}
    >
      {products?.map(product => (
        <Grid key={product.id} item xs={6} md={4} lg={3}
          component={m.div}
          {...varZoom({
            distance: 50,
            scale: 0.8
            // durationIn: 0.32,
            // durationOut: 0.24,
            // easeIn: 'easeInOut'
          }).inUp}
          sx={{
            cursor: 'pointer',
            // cursor: 'progress'
          }}
          onClick={() => navigate(`/products/${encodeURIComponent(product.id.split("/").pop())}`)}
        >
          <Product product={product} />
        </Grid>
      ))}
    </Grid>
  )
}