import PropTypes from 'prop-types';
// material
import { Container, Grid, TextField, Button, Typography } from '@material-ui/core';

// ----------------------------------------------------------------------

Newsletter.propTypes = {
	formOnly: PropTypes.bool,
	autoFocus: PropTypes.bool,
}

export default function Newsletter({ formOnly, autoFocus }) {
	return (
		<Container maxWidth="sm">
			{!formOnly && (
				<Typography variant="h2" gutterBottom>
					Join our newsletter
				</Typography>
			)}
			<form action="https://studio.us20.list-manage.com/subscribe/post?u=c03b11ba1c1834b06b2fa7d6b&amp;id=b414c5c829" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
				<Grid container rowGap={2}>
					<Grid item xs={12} md={8}>
						<TextField
							autoFocus={autoFocus}
							fullWidth
							variant="outlined"
							id="mce-EMAIL"
							name="EMAIL"
							type="email"
							placeholder="EMAIL"
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<Button
							fullWidth
							size="large"  
							variant="contained"
							id="mc-embedded-subscribe"
							name="subscribe"
							type="submit"
							sx={{ 
								height: "100%"
							}}
						>
							Subscribe
						</Button>
					</Grid>
				</Grid>
			</form>
		</Container>
	)
	// <p class="brandingLogo"><a href="http://eepurl.com/hSmfUn" title="Mailchimp - email marketing made easy and fun"><img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg" /></a></p>
}