// ----------------------------------------------------------------------

export default function Button(theme) {
	return {
		MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
			styleOverrides: {
				root: {
					padding: theme.spacing(0.5),
					// minWidth: 40,
					minWidth: 30,
					borderRadius: 0,
					cursor: 'pointer',
					boxShadow: 'none',
					'&:hover': {
						boxShadow: 'none'
					}
				},
				text: {
					backgroundColor: 'transparent',
					'&:hover': {
						backgroundColor: 'transparent'
					}
				},
				// containedPrimary: {
				// 	...theme.typography.overline,
				// 	textTransform: 'uppercase',

				// 	minWidth: 40,
				// 	backgroundColor: theme.palette.text.primary,
				// 	color: '#fff',

				// 	'&:hover': {
				// 		backgroundColor: theme.palette.text.primary
				// 	}
				// },
				outlinedPrimary: {
					// ...theme.typography.overline,
					// textTransform: 'uppercase',

					// minWidth: 40,
					// backgroundColor: '#fff',
					color: theme.palette.text.primary,
					border: `1px solid ${theme.palette.text.primary}`,
				},
				// outlined: {
				// 	// backgroundColor: '#fff',
				// 	padding: theme.spacing(0.5),
				// },
				// contained: {
				// 	// backgroundColor: theme.palette.text.primary,
				// 	// color: '#fff',
				// 	padding: theme.spacing(0.5),
				// },
				// text: {
				// 	backgroundColor: 'transparent',
				// 	'&:hover': {
				// 		backgroundColor: 'transparent'
				// 	}
				// }




				// sizeLarge: {
				// 	height: 48
				// },
				// // contained
				// containedInherit: {
				// 	color: theme.palette.grey[800],
				// 	// boxShadow: theme.customShadows.z8,
				// 	'&:hover': {
				// 		backgroundColor: theme.palette.grey[400]
				// 	}
				// },
				// // outlined
				// outlinedInherit: {
				// 	border: `1px solid ${theme.palette.grey[500_32]}`,
				// 	'&:hover': {
				// 		backgroundColor: theme.palette.action.hover
				// 	}
				// },
				// textInherit: {
				// 	'&:hover': {
				// 		backgroundColor: theme.palette.action.hover
				// 	}
				// }
			}
		}
	};
}