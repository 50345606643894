import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useEventListener from '@use-it/event-listener'
// material
import { Box, Container, Stack, Typography, useTheme } from '@material-ui/core';
// hooks
import useShopify from '../hooks/useShopify';
import useThemeMode from '../hooks/useThemeMode';
//
import Page from '../components/Page';
import Markdown from '../components/Markdown';
import LazySize from '../components/LazySize';

// ----------------------------------------------------------------------

const NEXT_KEYS = ['39', 'ArrowRight', '40', 'ArrowDown'];
const BACK_KEYS = ['37', 'ArrowLeft', '38', 'ArrowUp'];

export default function Archive() {
  const { posts } = useShopify();
  const { changeThemeMode } = useThemeMode();
  const theme = useTheme();

  const { postSlug } = useParams();
  const post = posts.find(post => post.attributes.slug === postSlug);

  const [imageIndex, setImageIndex] = useState(0);

  const images = post?.attributes.gallery?.data || [];

  const setImage = (newIndex) => {
    if (!images?.[0]) return;
    if (newIndex < 0) return setImageIndex(images.length + newIndex)
    setImageIndex(newIndex % images.length)
  }

  function handler({ key }) {
    if (NEXT_KEYS.includes(String(key))) nextImage();
    if (BACK_KEYS.includes(String(key))) previousImage();
  }

  useEventListener('keydown', handler);

  useEffect(() => {
    setImageIndex(0);
  }, [postSlug])

  const nextImage = () => setImage(imageIndex + 1);
  const previousImage = () => setImage(imageIndex - 1);

  changeThemeMode("light");

  const image = images?.[imageIndex];

  return (
    <Page title={post ? `${post.attributes.title.toUpperCase()} * RAUSCH` : "RAUSCH"}>
      <Container maxWidth="md">
        <Box sx={{
          position: "absolute",
          zIndex: 1,
          bottom: 89,
          right: 16,
          [theme.breakpoints.down("md")]: {
            bottom: 57,
          },
        }}>
          <Markdown alignRight caption children={post?.attributes.content || ""} />
          <Typography component="h1" variant="subtitle1" sx={{
            margin: theme => ({
              xs: theme.spacing(2, 0, 0),
              sm: theme.spacing(4, 0, 0),
            }),
            textAlign: "right",
            lineHeight: 0.8 // TODO: Adjust
          }}>
            {post?.attributes.title}
          </Typography>
        </Box>

        <Box sx={{
          position: "absolute",
          zIndex: 1,
          bottom: 86,
          left: 16,
          [theme.breakpoints.down("md")]: {
            bottom: 55,
          },
        }}>
          <Typography variant="caption" sx={{
            // margin: theme => theme.spacing(4, 0, 0),
          }}>
            {`${String((imageIndex + 1)).padStart(2, "0")} — ${String(images?.length).padStart(2, "0")}`}
          </Typography>
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
            [theme.breakpoints.down("md")]: {
              top: 100,
              bottom: 150,
            },
            [theme.breakpoints.down("sm")]: {
              top: 75,
            }
          }}
        >
          {image && (
            <Box
              sx={{
                position: "relative",
                width: "90%",
                height: {
                  xs: "100%",
                  md: "70%"
                },
              }}
            >
              <Box
                direction="row"
                alignItems="center"
                onClick={() => previousImage()}
                sx={{
                  zIndex: 1,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "50%",
                  bottom: 0,
                  cursor: "w-resize",
                }}
              />
              <Box
                direction="row"
                alignItems="center"
                onClick={() => nextImage()}
                sx={{
                  zIndex: 1,
                  position: "absolute",
                  top: 0,
                  width: "50%",
                  right: 0,
                  bottom: 0,
                  cursor: "e-resize",
                }}
              />
              <LazySize placeholder={image.attributes.formats?.small?.url || image.attributes.url} src={image.attributes.url} sx={{ width: "100%", height: "100%", objectFit: "contain" }} />
            </Box>
          )}
        </Stack>
      </Container>
    </Page>
  )
}