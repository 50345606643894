import { alpha } from "@material-ui/core"

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: '#fff'
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff'
};
const INFO = {
  lighter: '#DBF0FE',
  light: '#93C8FE',
  main: '#4B95FC',
  dark: '#2555B5',
  darker: '#0E2878',
  contrastText: '#fff'
};
const SUCCESS = {
  lighter: '#ECFDDB',
  light: '#B4F793',
  main: '#67E54B',
  dark: '#29A425',
  darker: 'xw#0E6D19',
  contrastText: '#fff',
};

const PRIMARY_LIGHT = {
  lighter: '#F3F3F0',
  light: '#BBBBB3',
  main: '#000',
  dark: '#000',
  darker: '#000',
  contrastText: '#fff'
};

const PRIMARY_DARK = {
  lighter: '#FFF',
  light: '#FFF',
  main: '#FFF',
  dark: '#F3F3F0',
  darker: '#BBBBB3',
  contrastText: '#000'
};

const INTRODUCTION = {
  background: PRIMARY_LIGHT.main,
  text: '#fff'
}

const COMMON = {
  common: { black: '#000', white: '#fff' },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  },

  introduction: { ...INTRODUCTION }
};

const palette = {
  light: {
    ...COMMON,
    primary: { ...PRIMARY_LIGHT },
    text: { primary: '#000', secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action }
  },
  dark: {
    ...COMMON,
    primary: { ...PRIMARY_DARK },
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: { paper: '#000', default: '#000', neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action }
  }
};

export default palette;
