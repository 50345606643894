import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material
import { Box, Stack, Typography } from '@material-ui/core';
// hooks
import useShopify from '../hooks/useShopify';
//
import LazySize from './LazySize';

// ----------------------------------------------------------------------

Product.propTypes = {
  product: PropTypes.object.isRequired
};

export default function Product({ product, ...other }) {
  const [imageSource, setImageSource] = useState(product.images[0]?.src);
  const [isHover, setIsHover] = useState(false);

  const { settings } = useShopify();

  const handleImageHover = (hover) => {
    if (hover) {
      const secondaryImage = product.images[1]?.src;
      if (secondaryImage) setImageSource(secondaryImage);
    } else {
      setImageSource(product.images[0]?.src);
    }
  }

  useEffect(() => {
    handleImageHover(isHover)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHover])

  const { settings: productSettings } = product.metafields;

  const isAnyVariantAvailable = !!product.variants.filter(variant => variant.available)[0];
  const isProductComingSoon = settings.coming_soon && productSettings?.coming_soon;

  return (
    <Box
      sx={{
        height: '100%',
        ...other
      }
      }>
      <Stack spacing={4} alignItems="center" justifyContent="flex-end" sx={{
        height: '100%'
      }}>
        <Box>
          <LazySize alt="Product Image" src={imageSource} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} />
        </Box>
        {<Typography variant="subtitle2" align="center" sx={{
          ...(isProductComingSoon) ? {
            color: "#0055ff"
          } : !isAnyVariantAvailable ? {
            color: "#ff0000"
          } : {},
        }}>
          {isProductComingSoon ? settings.coming_soon_text : !isAnyVariantAvailable ? 'Sold out' : product.title}
        </Typography>}
      </Stack>
    </Box >
  );
}