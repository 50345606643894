import React from 'react';
import ReactDOM from 'react-dom';

// lazy image
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';

ReactDOM.render(
	<StrictMode>
   		<HelmetProvider>
			<App />
		</HelmetProvider>
	</StrictMode>
	,
	document.getElementById('root')
);
