import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Client from 'shopify-buy/index.unoptimized.umd';
// import Client from 'shopify-buy';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';

import { ProgressBarStyle } from './layouts';

import { ShopifyProvider } from './contexts/ShopifyContext';
import { CheckoutProvider } from './contexts/CheckoutContext';
import { ThemeModeProvider } from './contexts/ThemeModeContext';

import MotionLazyContainer from './components/animate/MotionLazyContainer';
import ScrollToTop from './components/ScrollToTop';

const client = Client.buildClient({
  storefrontAccessToken: '23f75ff8275fe3c4c9a53e1a065cbc7b',
  domain: 'r-au-sch.myshopify.com',
  apiVersion: '2023-07'
});

const App = () => (
  <ShopifyProvider client={client}>
    <CheckoutProvider client={client}>
      <ThemeModeProvider>
        <ThemeConfig>
          <MotionLazyContainer>
            <BrowserRouter>
              <ProgressBarStyle />
              <ScrollToTop />
              <Router />
            </BrowserRouter>
          </MotionLazyContainer>
        </ThemeConfig>
      </ThemeModeProvider>
    </CheckoutProvider>
  </ShopifyProvider>
)

export default App;
// export default withSplashScreen(withConfirmScreen(App));
