import PropTypes from "prop-types";
import Countdown from "react-countdown";
// material
import { Container, Stack, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
// hooks
import useThemeMode from "../hooks/useThemeMode";
//
import Page from "../components/Page";
import LogoNew from "../components/LogoNew";

// ----------------------------------------------------------------------

MaintenanceShutdownCountdown.propTypes = {
  endDate: PropTypes.object,
  onComplete: PropTypes.func,
};

export default function MaintenanceShutdownCountdown({ endDate, onComplete }) {
  const theme = useTheme();
  const { changeThemeMode } = useThemeMode();

  changeThemeMode("dark");

  return (
    <Page title="RAUSCH" disablePadding>
      <Container
        maxWidth="md"
        sx={{
          minHeight: "100dvh",
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            py: 2,
          }}
        >
          <Typography
            variant="h1"
            align="center"
            sx={{
              lineHeight: 1.15,
              fontSize: {
                xs: "2.5rem",
                sm: "3rem",
                md: "6rem",
              },
            }}
          >
            <Countdown
              date={endDate}
              intervalDelay={10}
              precision={2}
              renderer={({
                days,
                hours,
                minutes,
                seconds,
                milliseconds,
                total,
              }) => {
                const isBefore3Days = total > 3 * 24 * 60 * 60 * 1000;

                if (isBefore3Days) {
                  return (
                    <Stack direction="row">
                      <Segment number={3} title="Day" />
                      <span>:</span>
                      <Segment number={0} title="Hr" />
                      <span>:</span>
                      <Segment number={0} title="Min" />
                      <span>:</span>
                      <Segment number={0} title="Sec" />
                      <span>:</span>
                      <Segment number={0} title="Ms" />
                    </Stack>
                  );
                }

                return (
                  <Stack direction="row">
                    <Segment number={days} title="Day" />
                    <span>:</span>
                    <Segment number={hours} title="Hr" />
                    <span>:</span>
                    <Segment number={minutes} title="Min" />
                    <span>:</span>
                    <Segment number={seconds} title="Sec" />
                    <span>:</span>
                    <Segment
                      number={Math.floor(milliseconds / 10)}
                      title="Ms"
                    />
                  </Stack>
                );
              }}
              onComplete={onComplete}
              /**
               * Note that this has no effect with the custom render.
               * Therefore I just calculate the hours myself.
               *
               * @source https://github.com/ndresx/react-countdown/issues/249
               */
              // daysInHours
            />
          </Typography>
        </Stack>

        <Stack
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            py: 2,
            zIndex: -1,
          }}
        >
          <LogoNew
            sx={{
              width: 48,
              color: theme.palette.text.primary,
            }}
          />
        </Stack>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

const Segment = ({ number, title }) => (
  <Stack
    direction="column"
    alignItems="center"
    sx={{
      /**
       * Since Arial is not a monospace font, the digit "1" is
       * just a little bit narrower than the other digits.
       * This leads to a wobbling effect when the digits change.
       * The minWidth fixes this and sets the width of the
       * segment to twice the widht of the digit "0".
       */
      minWidth: "2ch",
    }}
  >
    <span>{String(number).padStart(2, "0")}</span>
    <Typography
      component="p"
      variant="subtitle1"
      align="center"
      sx={{
        fontSize: {
          xs: "0.7rem",
          sm: "0.8rem",
          md: "1.5rem",
        },
      }}
    >
      {title}
    </Typography>
  </Stack>
);

Segment.propTypes = {
  number: PropTypes.number,
  title: PropTypes.string,
};
