import React, { Component } from "react";
// @mui
import { Box } from "@material-ui/core";
//
import MaintenanceShutdownCountdown from "../pages/MaintenanceShutdownCountdown";

// ----------------------------------------------------------------------

function withShutdownCountdownScreen(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        visible: true,
        removed: false,
      };

      this.hide = this.hide.bind(this);
    }

    endDate = new Date("2023-12-14T17:00:00Z");
    // endDate = Date.now() + 5000;

    hide() {
      this.setState({ visible: false });

      setTimeout(() => {
        this.setState({ removed: true });
      }, 1000);
    }

    render() {
      const { visible, removed } = this.state;

      if (removed) return <WrappedComponent {...this.props} />;

      return (
        <Box
          sx={{
            opacity: visible ? 1 : 0,
            transition: (theme) => theme.transitions.create("opacity"),
          }}
        >
          <MaintenanceShutdownCountdown
            endDate={this.endDate}
            onComplete={this.hide}
          />
        </Box>
      );
    }
  };
}

export default withShutdownCountdownScreen;
