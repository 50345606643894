// material
import { Box, Container, Link, Typography } from '@material-ui/core';
// hooks
import useShopify from '../hooks/useShopify';
import useThemeMode from '../hooks/useThemeMode';
//
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function Info() {
  const { infoPageLinks } = useShopify();
  const { changeThemeMode } = useThemeMode();

  changeThemeMode("dark");

  return (
    <Page title="INFO * RAUSCH">
      <Container maxWidth="md">
        <Box sx={{
          marginTop: {
            xs: 5,
            sm: 10,
            lg: 20
          },
          marginBottom: {
            xs: 5,
            sm: 10,
            lg: 20
          }
        }}>
          {infoPageLinks?.map(link => (
            <Typography key={link.id} variant="subtitle1" sx={{ mb: 2 }} textAlign="center">
              <Link href={link.url}>
                {/* Note that this is "Label" not "label"! */}
                {link.Label}
              </Link>
            </Typography>
          ))}
        </Box>
      </Container>
    </Page>
  )
}