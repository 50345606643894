import { useParams, useNavigate } from 'react-router-dom';
// material
import { Container, Box } from '@material-ui/core';
// hooks
import useShopify from '../hooks/useShopify';
import useThemeMode from '../hooks/useThemeMode';
//
import { contact, shipping, imprint, refund, privacy, terms } from '../components/Texts';
import Page from '../components/Page';
import Markdown from '../components/Markdown';

// ----------------------------------------------------------------------

export default function About() {
  const navigate = useNavigate();
  const { aboutPageContent } = useShopify();
  const { changeThemeMode } = useThemeMode();

  let content = aboutPageContent;

  const { contentTag } = useParams();

  if (contentTag) {
    switch (contentTag) {
      case "contact":
        content = contact
        break;
      case "shipping":
        content = shipping
        break;
      case "imprint":
        content = imprint
        break;
      case "refund":
        content = refund
        break;
      case "privacy":
        content = privacy
        break;
      case "terms":
        content = terms
        break;

      default:
        navigate("/about/imprint")
        break;
    }
  }

  changeThemeMode("dark");

  return (
    <Page title="ABOUT * RAUSCH">
      <Container maxWidth="md">
        <Box sx={{
          marginTop: {
            xs: 5,
            sm: 10,
            lg: 20
          },
          marginBottom: {
            xs: 5,
            sm: 10,
            lg: 20
          }
        }}>
          <Markdown children={content || ""} />
        </Box>
      </Container>
    </Page>
  )
}