import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import Layout from '../layouts';
// components
import LoadingScreen from '../components/LoadingScreen';
import Product from '../pages/Product'
import Products from '../pages/Products'
import Collection from '../pages/Collection'
import Cart from '../pages/Cart'
import About from '../pages/About'
import Info from '../pages/Info'
import Archive from '../pages/Archive'
import ArchiveCategory from '../pages/ArchiveCategory'
import Maintenance from '../pages/Maintenance'
import MaintenanceNewLogo2023 from '../pages/MaintenanceNewLogo2023'
import NewsletterMaintenance from '../pages/NewsletterMaintenance'
import withShutdownCountdownScreen from '../HOCs/withShutdownCountdownScreen.js';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
	return (
		<Suspense
			fallback={
				<LoadingScreen />
			}
		>
			<Component {...props} />
		</Suspense>
	);
};

const IS_MAINTENANCE = false;

function Router() {
	const MaintenanceRouter = useRoutes([
		{ path: '*', element: <MaintenanceNewLogo2023 /> },
	]);

	const RegularRouter = useRoutes([
		{ path: '/', element: <Home /> },
		{
			path: '/products',
			element: <Layout />,
			children: [
				{ path: '/', element: <Products /> },
				{ path: '/:productId', element: <Product /> },
			]
		},
		{
			path: '/collections',
			element: <Layout />,
			children: [
				{ path: '/', element: <Navigate to="/products" replace /> },
				{ path: '/:collectionId', element: <Collection /> },
			]
		},
		{
			path: '/archive',
			element: <Layout />,
			children: [
				{ path: '/', element: <Navigate to="/products" replace /> },
				{ path: '/:postSlug', element: <Archive /> },
			]
		},
		{
			path: '/archive-categories',
			element: <Layout />,
			children: [
				{ path: '/', element: <Navigate to="/products" replace /> },
				{ path: '/:archiveCategorySlug', element: <ArchiveCategory /> },
			]
		},
		{
			path: '/cart',
			element: <Layout />,
			children: [
				{ path: '/', element: <Cart /> },
			]
		},
		// {
		// 	path: '/about',
		// 	element: <Layout />,
		// 	children: [
		// 		{ path: '/', element: <About /> },
		// 	]
		// },
		{
			path: '/info',
			element: <Layout />,
			children: [
				{ path: '/', element: <Info /> },
			]
		},

		// Legal Stuff
		{
			path: '/about',
			element: <Layout />,
			children: [
				{ path: '/', element: <About /> },
				{ path: '/:contentTag', element: <About /> },
			]
		},

		// Fallback Routes
		{
			path: '*',
			children: [
				// { path: 'maintenance', element: <Maintenance /> },
				// { path: '500', element: <Page500 /> },
				// { path: '404', element: <NotFound /> },
				{ path: '*', element: <Navigate to="/" replace /> }
			]
		}
	]);

	if (IS_MAINTENANCE) {
		return MaintenanceRouter;
	}

	return RegularRouter;
}

export default withShutdownCountdownScreen(Router);

// IMPORT COMPONENTS

// -- Authentication
const Home = Loadable(lazy(() => import('../pages/Home.js')));
// const Cart = Loadable(lazy(() => import('../pages/Cart.js')));
// const Product = Loadable(lazy(() => import('../pages/Product')));
// const ProductsPage = Loadable(lazy(() => import('../pages/Products')));