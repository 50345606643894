import { withStyles } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

const GlobalStyles = withStyles((theme) => ({
  '@global': {
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box'
    },
    html: {
      width: '100%',
      height: '100%',
      '-ms-text-size-adjust': '100%',
      '-webkit-overflow-scrolling': 'touch',
      userSelect: 'none',
      fontSize: '14px'
      // overscrollBehavior: 'none',
      // margin: 0
    },
    body: {
      width: '100%',
      height: '100%',
      // overflow: 'hidden',
      overscrollBehavior: 'none',
      paddingBottom: 'env(safe-area-inset-bottom)'
      // position: 'fixed',
      // top: 0,
      // left: 0,
      // right: 0,
      // bottom: 0
    },
    '#root': {
      width: '100%',
      height: '100%'
    },
    input: {
      '&[type=number]': {
        MozAppearance: 'textfield',
        '&::-webkit-outer-spin-button': { margin: 0, WebkitAppearance: 'none' },
        '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
      }
    },
    textarea: {
      '&::-webkit-input-placeholder': { color: theme.palette.text.disabled },
      '&::-moz-placeholder': { opacity: 1, color: theme.palette.text.disabled },
      '&:-ms-input-placeholder': { color: theme.palette.text.disabled },
      '&::placeholder': { color: theme.palette.text.disabled }
    },
    a: { color: theme.palette.primary.main },
    img: { display: 'block', maxWidth: '100%' },

    // Lazy Load Img
    '.blur-up': {
      WebkitFilter: 'blur(20px)',
      filter: 'blur(20px)',
      transition: 'filter 1s, -webkit-filter 1s'
    },
    '.blur-up.lazyloaded ': {
      WebkitFilter: 'blur(0)',
      filter: 'blur(0)'
    },
    '.noscroll': {
      overflow: 'hidden'
    },
    '@keyframes highlight': {
      '0%': { transform: 'translate(0)' },
      '100%': { transform: 'translate(50%)' }
    }
  }
}))(() => null);

export default GlobalStyles;
