import { useState } from 'react';
import PropTypes from 'prop-types';
// material
import { alpha, experimentalStyled as styled, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Typography, Button, Box, Stack, Grid } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// hooks
import useShopify from '../hooks/useShopify';
//
import ImageGallery from '../components/ImageGallery';
import Markdown from '../components/Markdown';
import Modal from '../components/Modal';

// ----------------------------------------------------------------------

const TableRootStyle = styled('div')(({ theme }) => ({
  width: "100%",

  "table": {
    tableLayout: "fixed",
    borderCollapse: "collapse",
    width: "100%",
  },

  "td, th": {
    border: `1px solid ${theme.palette.text.primary}`,
    textAlign: "left",
    padding: "8px",
    ...theme.typography.overline,
  },

  "th": {
    // backgroundColor: "blue",
  }
}));

// ----------------------------------------------------------------------

ProductDisplay.propTypes = {
  product: PropTypes.object.isRequired,
  onAddToCart: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default function ProductDisplay({ product, onAddToCart, isLoading, ...other }) {
  const theme = useTheme();
  const { settings } = useShopify();

  const isUpMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { settings: productSettings, sizing: productSizing } = product.metafields;

  const [selectedVariant, setSelectedVariant] = useState(product.variants[0]);

  const [isSizeguideOpen, setIsSizeguideOpen] = useState(false);

  const handleSelectVariant = (variant) => {
    setSelectedVariant(variant);
  }

  const isProductComingSoon = settings.coming_soon && productSettings?.coming_soon;
  const originalPrice = Number(productSettings?.original_price);

  // TODO: Coming soon button more prominent?
  const button = (isProductComingSoon || !selectedVariant.available) ? (
    <Button disableRipple variant="contained" fullWidth sx={{ cursor: 'not-allowed' }}>
      {isProductComingSoon ? settings.coming_soon_text : 'Sold out'}
    </Button>
  ) : (
    <LoadingButton loading={isLoading} variant="outlined" fullWidth onClick={() => onAddToCart(selectedVariant.id)}>
      Add to cart
    </LoadingButton>
  )

  return (
    <Grid
      container
      spacing={{
        xs: 0,
        md: 3
      }}
      {...other}
    >
      <Grid item xs md xl={2}>

      </Grid>
      <Grid item xs={12} md={8} xl={8} sx={{
        [theme.breakpoints.down("md")]: {
          paddingBottom: "50vh",
        },
      }}>
        <ImageGallery images={product.images} />
      </Grid>
      <Grid item xs={12} md={4} xl={2} sx={{
        [theme.breakpoints.down('md')]: {
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "white",
          borderTop: "1px solid black",
          pt: 2,
          px: 2,
          pb: 7,
        }
      }}>
        <Stack spacing={2} alignItems={{
          xs: "stretch",
        }} sx={{
          [theme.breakpoints.up("md")]: {
            position: "sticky",
            top: "50%",
            transform: "translateY(-50%)",
          },
          [theme.breakpoints.up("lg")]: {
            transform: "translateY(-80%)",
          },
        }}>
          <Box>
            <Typography variant="h1">
              {product.title}
            </Typography>
            <Typography variant="subtitle1">
              {Number(selectedVariant.priceV2.amount).toFixed(0)}€
              {!!originalPrice && (
                <Typography variant="subtitle1" component="span" sx={{
                  textDecoration: "line-through",
                  color: "#FF0000",
                  ml: 1,
                }}>
                  {originalPrice.toFixed(0)}€
                </Typography>
              )}
            </Typography>
            <Typography variant="overline">
              Tax included - shipping calculated at checkout
            </Typography>
          </Box>
          <Stack spacing={1}>
            {product.variants.length > 1 && (
              <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ width: '100%' }}>
                {product.variants.map(variant => (
                  <Button key={variant.id} variant={selectedVariant === variant ? "contained" : "outlined"} onClick={() => handleSelectVariant(variant)}>
                    {variant.title}
                    {!variant.available && (
                      <Box sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        ...selectedVariant === variant ? {
                          background: `
                              linear-gradient(to top left,
                                ${alpha(theme.palette.background.default, 0)} 0%,
                                ${alpha(theme.palette.background.default, 0)} calc(50% - 0.7px),
                                ${alpha(theme.palette.background.default, 1)} calc(50% - 0.3px),
                                ${alpha(theme.palette.background.default, 1)} 50%,
                                ${alpha(theme.palette.background.default, 1)} calc(50% + 0.3px),
                                ${alpha(theme.palette.background.default, 0)} calc(50% + 0.7px),
                                ${alpha(theme.palette.background.default, 0)} 100%),
                              linear-gradient(to top right,
                                ${alpha(theme.palette.background.default, 0)} 0%,
                                ${alpha(theme.palette.background.default, 0)} calc(50% - 0.7px),
                                ${alpha(theme.palette.background.default, 1)} calc(50% - 0.3px),
                                ${alpha(theme.palette.background.default, 1)} 50%,
                                ${alpha(theme.palette.background.default, 1)} calc(50% + 0.3px),
                                ${alpha(theme.palette.background.default, 0)} calc(50% + 0.7px),
                                ${alpha(theme.palette.background.default, 0)} 100%);
                            `
                        } : {
                          background: `
                              linear-gradient(to top left,
                                ${alpha(theme.palette.text.primary, 0)} 0%,
                                ${alpha(theme.palette.text.primary, 0)} calc(50% - 0.7px),
                                ${alpha(theme.palette.text.primary, 1)} calc(50% - 0.3px),
                                ${alpha(theme.palette.text.primary, 1)} 50%,
                                ${alpha(theme.palette.text.primary, 1)} calc(50% + 0.3px),
                                ${alpha(theme.palette.text.primary, 0)} calc(50% + 0.7px),
                                ${alpha(theme.palette.text.primary, 0)} 100%),
                              linear-gradient(to top right,
                                ${alpha(theme.palette.text.primary, 0)} 0%,
                                ${alpha(theme.palette.text.primary, 0)} calc(50% - 0.7px),
                                ${alpha(theme.palette.text.primary, 1)} calc(50% - 0.3px),
                                ${alpha(theme.palette.text.primary, 1)} 50%,
                                ${alpha(theme.palette.text.primary, 1)} calc(50% + 0.3px),
                                ${alpha(theme.palette.text.primary, 0)} calc(50% + 0.7px),
                                ${alpha(theme.palette.text.primary, 0)} 100%);
                            `
                        }
                      }} />
                    )}
                  </Button>
                ))}
              </Stack>
            )}
            {button}
          </Stack>
          <Markdown caption children={product.descriptionHtml || ""} />

          {productSizing && (
            isUpMd ? (
              <SizeguideTable productSizing={productSizing} />
            ) : (
              <Button onClick={() => setIsSizeguideOpen(true)} variant="outlined">Size Guide</Button>
            )
          )}

          <Modal isOpen={isSizeguideOpen} onClose={() => setIsSizeguideOpen(false)}>
            <Stack direction="column" justifyContent="space-between" sx={{ flexGrow: 1 }}>
              <SizeguideTable productSizing={productSizing} />
            </Stack>
          </Modal>
        </Stack>
      </Grid>
    </Grid >
  )
}

const SizeguideTable = ({ productSizing }) => (
  <TableRootStyle>
    <table>
      <thead>
        <tr>
          <th>Model</th>
          <th>Height</th>
          <th>Size</th>
        </tr>
      </thead>
      <tbody>
        {productSizing["model-names"].map((name, index) => (
          <tr>
            <td>{name}</td>
            <td>{productSizing["model-heights"][index]}</td>
            <td>{productSizing["model-sizing"][index]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </TableRootStyle>
)