import { useParams } from 'react-router-dom';
// material
import { Box, Container, Link, Typography } from '@material-ui/core';
// hooks
import useShopify from '../hooks/useShopify';
import useThemeMode from '../hooks/useThemeMode';
//
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function ArchiveCategory() {
  const { archives: archiveCategories } = useShopify();
  const { changeThemeMode } = useThemeMode();

  const { archiveCategorySlug } = useParams();

  const archiveCategory = archiveCategories.find(archiveCategories => archiveCategories.attributes.slug === archiveCategorySlug);

  changeThemeMode("dark");

  return (
    <Page title={archiveCategory ? `${archiveCategory.attributes.title.toUpperCase()} * RAUSCH` : "RAUSCH"}>
      <Container maxWidth="md">
        <Box sx={{
          marginTop: {
            xs: 5,
            sm: 10,
            lg: 20
          },
          marginBottom: {
            xs: 5,
            sm: 10,
            lg: 20
          }
        }}>
          {archiveCategory?.attributes?.posts?.data?.map(post => (
            <Typography key={post.id} variant="subtitle1" sx={{ mb: 2 }} textAlign="center">
              <Link href={`/archive/${post.attributes.slug}`}>
                {post.attributes.title}
              </Link>
            </Typography>
          ))}
        </Box>
      </Container>
    </Page>
  )
}