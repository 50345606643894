import { useParams } from 'react-router-dom';
// material
import { Box, Container, Stack, Typography } from '@material-ui/core';
// hooks
import useShopify from '../hooks/useShopify';
import useThemeMode from '../hooks/useThemeMode';
//
import ProductGrid from '../layouts/ProductGrid';
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function Collection() {
  const { collections } = useShopify();
  const { changeThemeMode } = useThemeMode();

  changeThemeMode("light");

  const { collectionId } = useParams();
  const collection = collections.find(collection => decodeURIComponent(collection.id) === `gid://shopify/Collection/${collectionId}`);

  const products = collection?.products;

  return (
    <Page title={collection ? `${collection.title.toUpperCase()} * RAUSCH` : "RAUSCH"}>
      <Container maxWidth="lg">
        {products && products?.length > 0 && <ProductGrid products={products} sx={{ marginTop: theme => theme.spacing(0) }} />}
        {products && !products?.length > 0 && (
          <Stack alignItems="center" justifyContent="center" sx={{ my: 32 }}>
            <Typography variant="overline">No products available</Typography>
          </Stack>
        )}
        {!products && (
          <Stack alignItems="center" justifyContent="center" sx={{ my: 32 }}>
            <Typography variant="overline">Loading...</Typography>
          </Stack>
        )}
      </Container>
    </Page>
  )
}