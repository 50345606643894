import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { m } from "framer-motion";
// material
import { alpha, useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import { useMediaQuery, Box, AppBar, Hidden, Toolbar, Stack, Button, Grid, Typography, ButtonBase } from '@material-ui/core';
// hooks
import useShopify from '../hooks/useShopify';
import useCheckout from '../hooks/useCheckout';
// components
import LogoSmall from '../components/LogoSmall';
import Newsletter from '../components/Newsletter';
import Modal from '../components/Modal';
import MHidden from '../components/@material-extend/MHidden';
//
import isTouchDevice from '../utils/isTouchDevice';

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  position: "relative",
  alignItems: 'center',
  justifyContent: "center",
  [theme.breakpoints.up('lg')]: {
    alignItems: 'center',
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

NavigationContainer.propTypes = {
  logoOnly: PropTypes.bool,
  hidden: PropTypes.bool,
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  colorOverride: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.object
};

export default function NavigationContainer({
  logoOnly = false,
  hidden = false,
  isOpenSidebar,
  onCloseSidebar,
  colorOverride,
  children,
  sx = {}
}) {
  const { posts: postsAll, collections, archives, aboutPageContent, infoPageLinks } = useShopify();
  const { checkout } = useCheckout();
  const { pathname } = useLocation();
  const theme = useTheme();

  const posts = postsAll.filter(post => !post.attributes.archive.data);

  const isUpMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [isMenuBarHover, setIsMenuBarHover] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
    if (isSubscribeModalOpen) {
      setIsSubscribeModalOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  let menu = [
    {
      title: "Shop",
      children: [{
        title: "All",
        variant: "overline",
        url: "/products",
      }, ...collections.map(collection => ({
        title: collection.title,
        variant: "caption",
        url: `/collections/${encodeURIComponent(collection.id.split("/").pop())}`
      }))]
    }
  ]

  if (posts?.[0] || archives?.[0]) {
    let children = [];

    if (posts?.[0]) {
      children = children.concat(posts.map(post => ({
        title: post.attributes.title,
        variant: "caption",
        url: `/archive/${post.attributes.slug}`
      })))
    }

    if (archives?.[0]) {
      children = children.concat(archives.map(archive => ({
        title: archive.attributes.title,
        variant: "caption",
        url: `/archive-categories/${archive.attributes.slug}`
      })))
    }

    menu.push({
      title: "archive",
      children
    })
  }

  if (aboutPageContent) {
    menu.push({
      title: "about",
      url: "/about"
    })
  }

  if (infoPageLinks?.[0]) {
    menu.push({
      title: "info",
      url: "/info"
    })
  }

  const cartButton = (
    <Button
      variant="text"
      disableRipple
      component={RouterLink}
      to="/cart"
      sx={{ ...colorOverride && { color: colorOverride } }}
    >
      <Typography variant="subtitle1">
        {`CART(${checkout?.lineItems.reduce((partial_sum, lineItem) => partial_sum + lineItem.quantity, 0) || 0})`}
      </Typography>
    </Button>
  )

  const menuItem = (item, id, variant) => {
    let button = null;

    if (item.children?.[0]) {
      button = (
        <Button
          variant="text"
          disableRipple
          sx={{ minWidth: 0, textAlign: "left", cursor: "default", ...colorOverride && { color: colorOverride } }}
        >
          <Typography variant={variant ?? 'subtitle1'}>
            {item.title}
          </Typography>
        </Button>
      )
    } else {
      button = (
        <Button
          variant="text"
          disableRipple
          component={RouterLink}
          to={item.url || ""}
          sx={{
            minWidth: 0,
            ...colorOverride && { color: colorOverride }
          }}
        >
          <Typography variant={variant ?? 'subtitle1'} sx={{
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderColor: alpha(theme.palette.background.default, 0),
            ...pathname.includes(item.url) && {
              borderColor: colorOverride ?? theme.palette.text.primary
            }
          }}>
            {item.title}
          </Typography>
        </Button>
      )
    }
    return (
      <Box key={id}>
        {button}
        {item.children && (
          <Stack
            alignItems="flex-start"
            sx={{
              margin: {
                xs: theme.spacing(0, 0, 2),
                md: theme.spacing(0, 0, 1)
              },
              opacity: isMenuBarHover || isTouchDevice() || !isUpMd ? 1 : 0,
              transition: theme.transitions.create('opacity', {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.complex
              }),
            }}
          >
            {item.children.map((item, index) => menuItem(item, index, item.variant))}
          </Stack>
        )}
      </Box>
    )
  }

  const rightMenuItems = (
    <>
      {cartButton}
      {/* <Button
        variant="text"
        disableRipple
        onClick={() => setIsSubscribeModalOpen(true)}
        sx={{
          minWidth: 0,
          ...colorOverride && { color: colorOverride }
        }}
      >
        <Typography variant='subtitle1' sx={{
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          borderColor: alpha(theme.palette.background.default, 0),
          // ...pathname.includes(item.url) && {
          //   borderColor: theme.palette.text.primary
          // }
        }}>
          Subscribe
        </Typography>
      </Button> */}
    </>
  )

  return (
    <Box sx={{
      display: 'flex',
      width: '100%',
    }}>
      <AppBar
        sx={{
          boxShadow: 'none',
          color: theme.palette.text.primary,
          ...colorOverride && { color: colorOverride },
          backgroundImage: 'none',
          backgroundColor: 'transparent',
          transition: 'opacity 0.5s ease',
          opacity: hidden ? 0 : 1,
          zIndex: theme.zIndex.drawer,
          padding: {
            xs: theme.spacing(1, 0, 0),
            sm: theme.spacing(3.5, 3, 0)
          },
          ...sx
        }}
      >
        <ToolbarStyle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              position: "absolute",
              top: 0,
              left: 16,
              right: 16,
              bottom: 0,
              display: { xs: 'flex', md: "none" },
            }}
          >
            <Button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
              <Typography variant="subtitle1">
                Menu
              </Typography>
            </Button>
            {cartButton}
          </Stack>
          <ButtonBase
            variant="text"
            disableRipple
            component={RouterLink}
            to="/"
          >
            <LogoSmall sx={{
              width: {
                xs: 100,
                sm: 150
              },
              color: theme.palette.text.primary
            }} />
          </ButtonBase>
        </ToolbarStyle>
      </AppBar>
      <Stack
        onMouseEnter={() => setIsMenuBarHover(true)}
        onMouseLeave={() => setIsMenuBarHover(false)}
        direction="column"
        alignItems="flex-start"
        justifyContent="space-between"
        sx={{
          display: { xs: 'none', md: "flex" },
          pt: 5,
          pb: 10,
          px: 7,
          position: "fixed",
          top: 0,
          left: 0,
          height: "100%",
          width: {
            xs: 160,
            xl: 240,
          },
          zIndex: theme.zIndex.drawer
        }}
      >
        {menu.map((item, index) => menuItem(item, index))}
      </Stack>

      <Box sx={{
        position: "relative",
        flexGrow: 1,
        [theme.breakpoints.up('md')]: {
          mx: 20,
        },
        [theme.breakpoints.up('xl')]: {
          mx: 30,
        }
      }}>
        {children}
      </Box>

      <Stack
        direction="column"
        alignItems="flex-end"
        justifyContent="space-between"
        sx={{
          display: { xs: 'none', md: "flex" },
          pt: 5,
          pb: 10,
          px: 7,
          position: "fixed",
          top: 0,
          right: 0,
          height: "100%",
          width: {
            xs: 160,
            xl: 240,
          },
          zIndex: theme.zIndex.drawer,
        }}
      >
        {rightMenuItems}
      </Stack>
      <MHidden width="mdUp">
        <Modal isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)}>
          <Stack direction="row" justifyContent="space-between" sx={{ flexGrow: 1, mb: 8 }}>
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              {menu.map((item, index) => menuItem(item, index))}
            </Stack>
            <Stack
              direction="column"
              alignItems="flex-end"
              justifyContent="space-between"
            >
              {rightMenuItems}
            </Stack>
          </Stack>
        </Modal>
      </MHidden>
      <Modal isOpen={isSubscribeModalOpen} onClose={() => setIsSubscribeModalOpen(false)}>
        <Stack direction="column" justifyContent="center" sx={{ flexGrow: 1 }}>
          <Newsletter />
        </Stack>
      </Modal>
    </Box >
  );
}
